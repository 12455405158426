import React, { useState, useEffect, useRef } from 'react'
import { Popup, Swiper, Image } from 'antd-mobile'
import useActiveState from '../../lib/useActiveState'
import './css/Mask.css'

export default function Mask() {

  const [app] = useActiveState('app');
  const [start,setStart] = useState(false)
  const [hidden,setHidden] = useState(false)
  const [init,setInit] = useState(false)
  const [index,setIndex] = useState(0)
  const swiper = useRef()
  const array = ['', '', '']

  const items = array.map((item, index) => (
    <Swiper.Item key={index}>
      <div className="swipe-pic">
        {app.appname.search('01') !== -1 ? <Image src={`resources/mask/01-${index + 1}.gif`}/> :
         app.appname.search('05') !== -1 ? <Image src={`resources/mask/05-${index + 1}.gif`}/> : 
        <Image src={`resources/mask/${index + 1}_1.gif`}/>}
        <div className="swipe-pic-index">
          第{index+1}步
        </div>
      </div>
    </Swiper.Item>
  ))

  useEffect(() => {
    setTimeout(() => {
      setStart(true)
    }, 2500);
  }, [])

  useEffect(()=>{
    if (start && !init){
      setInit(true)
      setTimeout(() => {
        setHidden(true)
      }, 750);
      setTimeout(() => {
        setIndex(1)
      }, 2000);
    }
  },[start])

  useEffect(()=>{
    if (index == 0){
      setTimeout(()=>{
        setIndex(1)
        swiper.current.swipeNext()
      },2000)
    }else{
      const time = (index == 2 ? 2000 : 3000)
      setTimeout(()=>{
        setIndex(index == 2 ? 0 : 2)
        swiper.current.swipeNext()
      },time)
    }
  },[index])

  return (
    <div>
      <Popup className="mask-index"
        visible={true}
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          overflow: 'hidden'
        }}>
        <div className="mask-header">
          <Image className="mask-header-icon" src={app.appicon}/>
          <div className="mask-content">
            <div className="mask-content-title">快速添加桌面应用</div>
            <div className="mask-content-desc">便捷启动·全屏体验</div>
          </div>
        </div>
        <div className="mask-container">
          {hidden ? <Swiper 
            defaultIndex={0} 
            ref={swiper}
            loop={true}>{items}</Swiper> : null}
          <div className="mask-pic-index" style={{opacity:(start ? '0' : '1'),display:(hidden ? 'none' : '')}}>
            <Image className="mask-pic" src="resources/mask/start.png"/>
          </div>
        </div>
      </Popup>
    </div>
  )
}
