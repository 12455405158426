import React, { useEffect, useRef, useState } from 'react'
import "./css/hangup.css"
import { CSSTransition } from 'react-transition-group'
import { CloseOutlined, RightOutlined } from '@ant-design/icons'
import { NavLink } from '../../../lib/Navigation'
import general from '../../../general'
import Dialog from '../../../lib/Dialog'
import URI from "urijs";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import $ from "jquery";
import { InfiniteScroll, List } from 'antd-mobile'
import { Swiper } from 'antd-mobile'
import useActiveState from '../../../lib/useActiveState'
import { useLockFn } from 'ahooks'
import useUrlState from "@ahooksjs/use-url-state";

export default function ClundHangUp(props) {

  const [init,setInit] = useState(false)
  const [ownDevice,setOwnDevice] = useState(false)
  const [is_new_person,setIs_new_person] = useState(false)
  const [useAlert,setUseAlert] = useState(false)
  const [editAlert,setEditAlert] = useState(false)
  const [gameAlert,setGameAlert] = useState(false)
  const [alertString,setAlertString] = useState('')
  const [searchKey,setSearchKey] = useState('')
  const [gameList,setGameList] = useState([])
  const [deviceList,setDeviceList] = useState([])
  const [showAllDevice,setShowAllDevice] = useState(false)
  const [device,setDevice] = useState()
  const [buy_url,setBuy_url] = useState()
  const [page,setPage] = useState(1)
  const [more,setMore] = useState(false)
  const [loading,setLoading] = useState(false)
  const [handleSearch,setHandleSearch] = useState(false)
  const titleRef = useRef()
  const history = useHistory();
  const [user, setUser] = useActiveState('user');
  const [chat,setChat] = useState([]);
  const [isCheckAuth, setIsCheckAuth] = useState(false);
  const [config, setConfig] = useActiveState('config');
  const [authData, setAuthData] = useState({});
  const [params, setParams] = useUrlState({}, {navigateMode: 'replace'});

  const usePageVisibility=(onVisible,onHidden)=>{
    useEffect(()=>{
      const handlevisibilitychange =()=>{
        if(document.visibilityState == "visible"){
          onVisible && onVisible();
        } else {
          onHidden && onHidden();
        }
      }
      document.addEventListener("visibilitychange", handlevisibilitychange);
      return()=>{
        document.removeEventListener("visibilitychange",handlevisibilitychange);
      };
    },[onVisible, onHidden]);
  }

  usePageVisibility(()=>{
    urlLogin()
  },()=>{
    
  })

  const urlLogin = () => {
    if (params.userid && params.username && params.token) {
      if (window.navigator.standalone && localStorage.getItem('logout')) {
        return;
      }
      localStorage.setItem('user_id', params.userid);
      localStorage.setItem('username', params.username);
      localStorage.setItem('token', params.token);
      load_load()
    }
  }

  const load_load = useLockFn(async () => {
    await general.axios.post('/user/user/getMemberInfo')
      .finally(() => {
      })
      .then(response => {
        let {status, data} = response.data;
        if (!status.succeed) {

        } else {
          setUser({isLogin: true, ...data.member_info});
        }
      })
      .catch(err => {
          Dialog.error(err.message);
        });
  })

  useEffect(()=>{
    urlLogin()
  },[])
  
  useEffect(()=>{
    if (props.active){
      getMyCloudFacilityList()
    }
  },[props.active])

  useEffect(()=>{
    if (localStorage.getItem('username')){
      getMyCloudFacilityList()
    }
  },[localStorage.getItem('username')])

  useEffect(()=>{
    if (deviceList.length > 1){
      props.setOperate(<div style={{display:'flex',alignItems:'center'}} onClick={handleAllDevices}>
        <span>{device?.title}</span>
        <img style={{paddingLeft:'5px',color:'#666'}} height={7} src="resources/hangup/top.png" />
      </div>)
    }
  },[deviceList,device])

  const handleAllDevices = ()=>{
    setShowAllDevice(e=>!e)
  }

/**
 * 设备列表
 */
  const getMyCloudFacilityList = ()=>{
    if (!init){
      Dialog.loading()
    }
    general.axios.post('getMyCloudFacilityList')
    .finally(()=>{
      Dialog.close()
    })
    .then(res=>{
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
        return;
      }
      if (!init){
        setChat(data.groupHistoryRecord)
      }
      setInit(true)
      setDeviceList(data.list)
      setOwnDevice(data.list.length > 0)
      setIs_new_person(data.is_new_person)
      setBuy_url(data.buy_url)
      if (data.list.length > 0){
        setDevice(data.list[0])
      }
    })
  }
/**
 * 
 * 搜索游戏
 */
  const handleSearchGame = (params={}) => {
    if (loading){
      return
    }
    setLoading(true)
    general.axios.post('getSelectCloudGameList',{game_name:searchKey,pagination:{page:params.page,count:10}})
    .finally(()=>{
       Dialog.close()
       setLoading(false)
    })
    .then(res=>{
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
        return;
      }
      setHandleSearch(true)
      params.page && setPage(params.page);
      setGameList(gameList => params.page == 1 ? data.game_list : [...gameList,...data.game_list])
      setMore(data.paginated.more)
    })
    .catch(err => {
       Dialog.error(err.message);
     });
  }

/**
 * 试用
 */
  const getCloudProbation = () => {
    Dialog.loading()
    general.axios.post('getCloudProbation')
    .finally(()=>{
       Dialog.close()
    })
    .then(res=>{
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
        return;
      }
      getMyCloudFacilityList()
    })
  }
/**
 * 修改名称
 */
  const alterCloudTitle = () => {
    if (titleRef.current.value.length > 8){
      Dialog.error('设备名称不能超过8个字符')
      return
    }
    Dialog.loading()
    general.axios.post('alterCloudTitle',{title:titleRef.current.value,id:device?.id})
    .finally(()=>{
       Dialog.close()
    })
    .then(res=>{
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
        return;
      }
      getMyCloudFacilityList()
    })
  }
/**
 * 刷新
 */
  const cloudRefreshCapture = (id)=>{
    Dialog.loading()
    general.axios.post('cloudRefreshCapture',{id})
    .finally(()=>{
       Dialog.close()
    })
    .then(res=>{
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
        return;
      }
      setDevice(data)
    })
  }
/**
 * 开始云挂机
 * @param {} gameid 
 */
  const initiateCloudGame = (gameid)=>{
    Dialog.loading()
    general.axios.post('initiateCloudGame',{game_id:gameid,id:device.id})
    .finally(()=>{
       Dialog.close()
    })
    .then(res=>{
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
        return;
      }
      let hrefUrl = URI('/iframenogen')
        .addQuery('url', URI(data))
        .addQuery('gamefullscreen', 1)
        .toString();
      history.push(hrefUrl)
    })
  }
/**
 * 结束云挂机
 */
  const cloudStopHanging = ()=>{
    Dialog.loading()
    general.axios.post('cloudStopHanging',{id:device.id})
    .finally(()=>{
       Dialog.close()
    })
    .then(res=>{
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
        return;
      }
      cloudRefreshCapture(device.id)
    })
  }

  useEffect(()=>{
    if (props.active){
      window.addEventListener('message', function(event) {
        var result = event.data;
        if(result?.type) {
          if(result?.type == 'pay') {
            window.location.href = result?.pay_url;
          } else if(result?.type == 'exitCloudApp') {
            // setStartState(false);
            // history.push('/?gameid=' + gameInfo.maiyou_gameid)
          } else if(result?.type == 'kefu') {
            history.push('/service')
          } else if(result?.type == 'clouddevpay') {
            history.goBack()
          }
        } else {
          if (result?.pay_url){
            window.location.href = result?.pay_url;
          }
        }
      });
    }
  },[]);
  
  const certifiy = useLockFn(()=>{
    if(!authData.real_name) {
      Dialog.info('请输入姓名');
      return;
    }
    if(!authData.identity_card){
      Dialog.info('请输入身份证号');
      return;
    }
    Dialog.loading();
    general.axios.post('/user/user/editMemberInfo', {real_name:authData.real_name, identity_card:authData.identity_card})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        
        if (!status.succeed) {
          Dialog.info(status.error_desc);
        } else {
          Dialog.info("认证成功");
          setIsCheckAuth(false);
          // setUser({...user, isRealNameAuth: true});
          setUser({...user, ...{real_name: authData.real_name, identity_card: authData.identity_card, isRealNameAuth: true, isAdult: data.isAdult}});
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
    })

  return (
    <div className='hangup-index'>
      {init ? 
      <div className='hangup-container'>
      <NavLink user={true} onClick={()=>{
          if (user.isCheckAuth && !user.isRealNameAuth){
            setIsCheckAuth(true)
            return
          }
          general.axios.post('/base/yunxin/joinTeams',{topic_id:"9999998"})
          .then(res=>{
            const { data, status} = res.data
            if (status.succeed){
              const {tid} = data.info
              if (localStorage.getItem('yx_login')){
                history.push(`/chat?tid=${tid}`)
              }else{
                PubSub.publish('loginSubscribe',true)
                setTimeout(()=>{
                  history.push(`/chat?tid=${tid}`)
                },2000)
              }
            }else{
              Dialog.error(status.error_desc)
            }
          })
        }}>
        <div className="hangup-chat">
          <div className="message">
            <Swiper 
              direction="vertical"
              loop
              autoplay
              autoplayInterval={3000}
              indicator={() => null}
              style={{ '--height': '21px' }}
              >
              {chat.map((item, index) => (
                <Swiper.Item key={index}>
                  <img src={item.icon} alt="" />
                  <div className="content">{item.msg}</div>
                </Swiper.Item>
              ))}
            </Swiper>
            <span className="join">加入云挂机问题反馈群</span>
            <RightOutlined style={{color:"#999999",fontSize:'10px'}}></RightOutlined>
          </div>
        </div>
        </NavLink>
        {ownDevice ? 
        <div className='hangup-container-content'>
          <div className='hangup-own'>
            {device?.idle == 2 ? <img onClick={()=>{
              let hrefUrl = URI('/iframenogen')
                .addQuery('url', URI(device.outboard_url))
                .addQuery('gamefullscreen', 1)
                .toString();
              general.history.push(hrefUrl)
            }} className='device-image' src={device?.screenshot}/>:null}
            <div className="device-info" style={{background:device?.idle == 1 ? '' : 'linear-gradient(black, transparent)'}}>
              <div className="device-name" onClick={()=>setEditAlert(true)}>
                <span className={(device?.idle == 1 ? "device-idle" : "device-hangup")}>{device?.title}</span>
                <img className='editIcon' src={'resources/hangup/' + (device?.idle == 1 ? 'edit' : 'edit-white') + '.png'}></img>
              </div>
              <div className={"device-time " + (device?.idle == 1 ? "device-idle" : "device-hangup")}>到期时间：{device?.expire_time}</div>
              {device?.idle == 2 ? <div className={"gamename " + (device?.idle == 1 ? "device-idle" : "device-hangup")}>游戏名称：{device?.gamename}</div> : null}
              <span className={"device-state " + (device?.idle == 1 ? "device-state-idle" : "device-state-hangup")}>{device?.idle == 1 ? '闲置中' : '挂机中'}</span>
            </div>
            {device?.idle == 1 ? 
            <div className="device-game" onClick={()=>{
              setGameAlert(true)
              handleSearchGame({page:1})
            }}>
              <img src="resources/hangup/shoubing.png" alt="" />
              <div className='title'>暂无启动游戏</div>
              <div className='desc'>点击此处选择游戏云挂机</div>
            </div>:null}
            <div className="device-action">
              <div className="action-item" onClick={()=>cloudRefreshCapture(device.id)}>
                <img src="resources/hangup/refresh.png" alt="" />
                <span>刷新</span>
              </div>
              <NavLink user={true} onClick={()=>{
                if (user.isCheckAuth && !user.isRealNameAuth){
                  e.stopPropagation()
                  e.preventDefault()
                  setIsCheckAuth(true)
                  return
                }
              }} to={device?.renew_url+'&from=react'} iframe={{title:'续费云手机',sinfo:'hangup'}} className="action-item">
                <img src="resources/hangup/buy.png" alt="" />
                <span>续费</span>
              </NavLink>
              {device?.idle == 2 ?
              <div className="action-item" onClick={()=>{
                setAlertString('退出挂机会导致云手机中的游戏缓存数据清空，相关游戏挂机设置会重置，是否确认退出当前挂机游戏?')
                setUseAlert(true)
              }}>
                <img src="resources/hangup/exit.png" alt="" />
                <span>退出挂机</span>
              </div>:null}
            </div>
            <NavLink to="/hangupquestion" className="question">
              <img src="resources/hangup/question.png" alt="" />
            </NavLink>
          </div>
          <NavLink user={true} onClick={()=>{
            if (user.isCheckAuth && !user.isRealNameAuth){
              e.stopPropagation()
              e.preventDefault()
              setIsCheckAuth(true)
              return
            }
          }} to={buy_url+'&from=react'} iframe={{title:'购买云挂机',sinfo:'hangup',id:"iframe"}} className="device-buy">
            <img src="resources/hangup/add.png"/>
            <span>购买设备</span>
          </NavLink>
          {deviceList.length > 1 && showAllDevice ?
            <div className="device-list" onClick={()=>setShowAllDevice(false)}>
              <div className="device-list-container">
              {deviceList.map((item,index)=>
                <div key={index} onClick={()=>cloudRefreshCapture(item.id)} className="device-list-item">{item.title}</div>
              )}
              </div>
          </div> : null}
        </div> : 
        <div className='hangup-noown' onClick={()=>{
          setGameAlert(true)
          handleSearchGame({page:1})
          }}>
          <div className='title'>云挂机可以持续不掉线</div>
          <div className='title'>并支持多开哦</div>
          <div className='desc'>点击此处查看支持云挂机的游戏</div>
          <div className="func">
            <img src="resources/hangup/right.png" alt="" />
            <span className='text'>功能特性</span>
            <img src="resources/hangup/left.png" alt="" />
          </div>
          <div className="item">
            <div className="icon">
              <img src="resources/hangup/time.png" alt="" />
            </div>
            <div className="content">
              <div className='title'>24小时在线</div>
              <div className='sub'>锁屏也能持续运行</div>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <img src="resources/hangup/member.png" alt="" />
            </div>
            <div className="content">
              <div className='title'>不占手机空间</div>
              <div className='sub'>没有存储限制</div>
            </div>
          </div>
          {is_new_person ? 
          <div className="use" onClick={e=>{
              if(!general.isLogin()) {
                PubSub.publish('alert-login',true)
                e.stopPropagation()
                return;
              }
              e.stopPropagation()
              setAlertString('新人可以免费试用4小时的云挂机功能，确认后即开始生效，不管是否使用到时间后都会过期，是否确认开始？')
              setUseAlert(true)}
            }>新人免费试用（4.0小时）</div> :
          <NavLink onClick={e=>{
            e.stopPropagation()
          }} to={buy_url+'&from=react'} user={true} iframe={{title:'续费云手机',sinfo:'hangup'}} className="use">购买设备</NavLink>
          }
          <NavLink onClick={e=>{
            e.stopPropagation()
          }} to="/hangupquestion" className="turo">
            <span>查看教程</span>
            <img src="resources/hangup/more.png" alt="" />
          </NavLink>
        </div>}
      </div> : null}

      {isCheckAuth?
        <div className="pay-check-auth">
        <div className="auth-index">
          <div className="close" onClick={() => setIsCheckAuth(false)}>
            <img src="/resources/user/close.png" />
          </div>
          <div className="title">
            <div className="title-img">
              <img src="/resources/user/auth-title.png" />
            </div>
            <div>
              <h3>实名认证</h3>
              <p>
              {config.authTopTips}
              </p>
            </div>
          </div>

          <div className="auth-user-realname">
            <div className="auth-user-input">
              <div className="name">真实姓名</div>
              <input name="nick_name" type="text" placeholder="请输入姓名" autoComplete="off" value={authData.real_name} onChange={e => setAuthData({...authData, real_name: e.target.value})} />
            </div>
            <div className="auth-user-input">
              <div className="name">身份证号</div>
              <input name="number" type="text" placeholder="请输入身份证号" autoComplete="off" value={authData.identity_card} onChange={e => setAuthData({...authData, identity_card: e.target.value})} />
            </div>
            <span>
            {config.authBottomTips}
            </span>
            <div className="auth-user-btn" onClick={certifiy}>立即认证</div>
          </div>
        </div>
        </div> : null
      }

      <CSSTransition in={useAlert} timeout={250} unmountOnExit={true}>
        <div className="use-alert">
          <div className="use-mask" onClick={()=>setUseAlert(false)}></div>
          <div className="use-container">
            <div className='title'>温馨提示</div>
            <CloseOutlined className="use-close" onClick={()=>setUseAlert(false)}></CloseOutlined>
            <div className='content'>{alertString}</div>
            <div className="submit" onClick={()=>{
              setUseAlert(false)
              if (device){
                cloudStopHanging()
              }else{
                getCloudProbation()
                setOwnDevice(true)
              }
            }}>确定</div>
            <div className="cancel" onClick={()=>setUseAlert(false)}>取消</div>
          </div>
        </div>
      </CSSTransition>

      <CSSTransition in={editAlert} timeout={250} unmountOnExit={true}>
        <div className="use-alert">
          <div className="use-mask" onClick={()=>setEditAlert(false)}></div>
          <div className="use-container">
            <div className='title'>修改设备名称</div>
            <CloseOutlined className="use-close" onClick={()=>setEditAlert(false)}></CloseOutlined>
            <input className='input' placeholder='请输入设备名称' ref={titleRef} defaultValue={device?.title} type="text"/>
            <div className="submit" onClick={()=>{
              setEditAlert(false)
              setOwnDevice(true)
              alterCloudTitle()
            }}>确定</div>
            <div className="cancel" onClick={()=>setEditAlert(false)}>取消</div>
          </div>
        </div>
      </CSSTransition>

      <CSSTransition in={gameAlert} timeout={250} unmountOnExit={true}>
        <div className="use-alert">
          <div className="use-mask" onClick={()=>{
            setPage(1)
            setGameAlert(false)
            setGameList([])
            setSearchKey('')
          }}></div>
          <div className="use-container" style={{backgroundColor:'#F5F6F8',height:'70vh'}}>
            <div className='title'>选择游戏</div>
            <CloseOutlined className="use-close" onClick={()=>{
              setPage(1)
              setGameAlert(false)
              setGameList([])
              setSearchKey('')
            }}></CloseOutlined>
            <div className="search">
              <input className='searchbar' value={searchKey} type="text" placeholder='请输入游戏名' onChange={e=>{
                setSearchKey(e.target.value);
              }}/>
              <img className='search-delete' src="resources/hangup/delete.png" onClick={()=>setSearchKey('')} />
              <div className="search-submit" onClick={()=>{
                setHandleSearch(false)
                setGameList([])
                handleSearchGame({page:1})
              }}>搜索</div>
            </div>
            <div id='search-game-list' className="list">
              <List>
              {gameList.map((item,index)=>
                <List.Item key={index} 
                    className="gameitem" 
                    onClick={()=>{
                      if (!general.isLogin()){
                        setGameAlert(false)
                        PubSub.publish('alert-login',true)
                        return
                      }
                      if (item.use_status == 0){
                        Dialog.error('该游戏暂不支持云挂机')
                        return
                      }
                      if (deviceList.length == 0){
                        Dialog.error('暂无设备，请前往购买')
                        return
                      }
                      setPage(1)
                      setSearchKey('')
                      setGameList([])
                      setGameAlert(false)
                      initiateCloudGame(item.maiyou_gameid)
                    }}>
                  <div className="icon">
                    <img src={item.game_image.thumb}/>
                  </div>
                  <div className="content">
                    <div className="info">
                      <div className="name">
                        <span className="game-name">{item.game_name}</span>
                        {item.nameRemark ? <span className="name-remark">{item.nameRemark}</span> : null}
                      </div>
                      <div className="type">{item.game_classify_type}</div>
                    </div>
                    <div className={"action " + (item.use_status == 0 ? 'notsupport' : 'support')}>{item.use_status == 0 ? '不支持' : '云挂机'}</div>
                  </div>
                </List.Item>
              )}
              </List>
              {gameList.length > 0 ?
              <InfiniteScroll loadMore={()=>handleSearchGame({page:page+1})} hasMore={more} /> : null
              }
              { 
                gameList.length == 0 && handleSearch ? 
                <div className="empty">
                  <img className="icon" src="resources/hangup/placeholder.png" alt="" />
                  <div className="desc">暂无此游戏</div>
                </div> : null 
              }
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  )
}
