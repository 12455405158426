import {useEffect, useState} from "react";
import general from "../../general";
import moment from "moment";
import {NavLink} from "../../lib/Navigation";
import Dialog from "../../lib/Dialog";
import {Link, useHistory} from "react-router-dom";
import useActiveState from "../../lib/useActiveState";
import { useLockFn } from "ahooks";
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation, Pagination} from "swiper";
import useUrlState from "@ahooksjs/use-url-state";

SwiperCore.use([Navigation]);

export default props => {
  const [calendar, setCalendar] = useState([]);
  const history = useHistory();
  useEffect(() => {
    let days = [];
    let startDay = moment(moment().format('YYYY-MM-01')).day();
    if (startDay !== 0) {
      for (let i = 0; i < startDay; i++) {
        days.push(moment(moment().format('YYYY-MM-01')).add(-(i + 1), 'days'));
      }
      days.reverse();
    }
    let i = 0;
    while (true) {
      let day = moment(moment().format('YYYY-MM-01')).add(i, 'days');
      if (day.format('YYYY-MM-DD') === moment().add(1, 'months').format('YYYY-MM-01')) {
        break;
      }
      days.push(day);
      i++;
    }
    while (true) {
      if (days.length % 7 !== 0) {
        let tmp = days[days.length - 1];
        days.push(moment(tmp).add(1, 'days'));
      } else {
        break;
      }
    }
    setCalendar(days);
  }, []);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState({});
  const [signInfo, setSignInfo] = useState({});
  const [sign, setSign] = useState(false);
  const [dataSignInfo, setDataSignInfo] = useState({});
  const [user, setUser] = useActiveState('user');
  const [app] = useActiveState('app');
  const [dates, setDates] = useState();
  const [routerIndex, setRouterIndex] = useState(1);
  const [init, setInit] = useState(false);
  const [params, setParams] = useUrlState({}, {navigateMode: 'replace'});

  const load =  useLockFn(async () => {
    Dialog.loading();
    await general.axios.post('/user/Sign2407/getSignData')
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          // setLoaded(true);
          let {status, data} = res.data;
          // console.log(data);
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            let result = [];   //结果数组 
            let len = 7;

            for(var i=0;i<data.signData.length;i+=len){        
              result.push(data.signData.slice(i,i+len));    //循环取原数组N个元素,每次取从上次取的下一个开始取。
            }    
            setDates(result);
            setData(data);
            setInit(true);
            // setData({...data, ...data.user_info});
            // setSignInfo(data.sign_info);
            // setDataSignInfo(data.user_info);
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });

  // console.log(dates);

  useEffect(() => {
    load();
  }, []);

  const urlLogin = () => {
    if (params.userid && params.username && params.token) {
      localStorage.setItem('user_id', params.userid);
      localStorage.setItem('username', params.username);
      localStorage.setItem('token', params.token);

      load_load();
    }
  }
  const load_load = useLockFn(async () => {
    await general.axios.post('/user/user/getMemberInfo')
      .finally(() => {
      })
      .then(response => {
        let {status, data} = response.data;
        if (!status.succeed) {

        } else {
          setUser({isLogin: true, ...data.member_info});
        }
      })
      .catch(err => {
          Dialog.error(err.message);
        });
  })
  useEffect(()=>{
    urlLogin()
  },[]);
  
  const [swiper, setSwiper] = useState(null);
  useEffect(() => {
    if (swiper) {
      swiper.update();
    }
  }, [swiper]);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(routerIndex, 0, false);
    }
  }, [routerIndex, swiper]);

  const signOperate = useLockFn(() => {
    if (!user.mobile) {
      Dialog.confirm({
        title: '没有绑定手机号，现在去绑定？',
        yes: () => {
          history.push('/user/phone');
        }
      });
    } else {
      if (!data.todaySigned) {
        Dialog.loading();
        general.axios.post('/user/Sign2407/sign')
          .finally(() => {
            Dialog.close();
          })
          .then(response => {
            let result = response.data;
            if (!result.status.succeed) {
              Dialog.error(result.status.error_desc);
            } else {
              load();
            }
          })
          .catch(error => {
            Dialog.error(error.message);
          });
      } else {
        Dialog.info("今日已经签过到了");
      }
    }
  })

  const criticalHit = () => {
    if (!user.mobile) {
      Dialog.confirm({
        title: '没有绑定手机号，现在去绑定？',
        yes: () => {
          history.push('/user/phone');
        }
      });
    } else {
      history.push('/save/newvip?levelid=3');
    }
  }

  // console.log(data.vip_add_balance);
  useEffect(()=>{
    props.setOperate(
      <NavLink user={true} to="/user/bill?type=2">金币记录</NavLink>
    )
  },[props.active])

  useEffect(()=>{
    load()
  },[localStorage.getItem('username')])

  return <div className="signdata-index" style={{backgroundImage: 'url(/resources/task/bg.png)'}}>
    <div className="task-money">
      <div className="money">
        <div className="label">当前金币（个）</div>
        <div className="value">{data.balance}</div>
      </div>
      {/* <Link to="/user/bill?type=2" className="btn"><img src="/resources/task/money.png" alt=""/>金币记录</Link> */}
    </div>
    <div className="task-sign">
      <div className="title"><img src="/resources/task/sign-course.png" alt=""/></div>
      {/* <div className="info">本月已连续签到：<span>{data.month_keep_day}天</span></div> */}
      <div className="progress-new">
        {/* <div className="tips" style={{backgroundImage:'url(/resources/task/sign-tips.png)'}}>连续签满28天最高可获得768金币</div> */}
        <div className="hx"><span style={{width: '' + (data.month_keep_day / 28 * 100) + '%'}}/></div>
        <ul>
          {/* <li className={data.month_keep_day >= 3 ? 'active' : ''} style={{left: '12.741%'}}>
            <div className="value">+10</div>
            <div className="label">3天</div>
          </li>
          <li className={data.month_keep_day >= 7 ? 'active' : ''} style={{left: '30%'}}>
            <div className="value">+20</div>
            <div className="label">7天</div>
          </li>
          <li className={data.month_keep_day >= 15 ? 'active' : ''} style={{left: '57%'}}>
            <div className="value">+50</div>
            <div className="label">15天</div>
          </li>
          <li className={data.month_keep_day >= 28 ? 'active' : ''} style={{left: '100%'}}>
            <div className="value"><img src="/resources/task/sign-gift.png" alt=""/></div>
            <div className="label">28天</div>
          </li> */}
        </ul>
        
        <div className="dates-list">
          <Swiper
            onSwiper={setSwiper} 
            onSlideChange={swiper => setRouterIndex(swiper.activeIndex)}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
              disabledClass: 'disable'
            }}
          >
            {
              dates ? 
                dates?.map(item => <SwiperSlide>
                  <div className="items">
                    {
                      item.map(d => <div className={'date-number ' + (d.isToday ? 'active' : '')}>
                        <div className="value">{d.text}</div>
                        <div className="label">{d.signDate}</div>
                      </div>)
                    }
                  </div>
                </SwiperSlide>)
              : null
            }
            {/* <div className="items">
              <div className="date-number">
                <div className="value">未签</div>
                <div className="label">07.09</div>
              </div>
              <div className="date-number">
                <div className="value">+10</div>
                <div className="label">07.10</div>
              </div>
              <div className="date-number">
                <div className="value">+10</div>
                <div className="label">07.11</div>
              </div>
              <div className="date-number">
                <div className="value">+10</div>
                <div className="label">07.12</div>
              </div>
              <div className="date-number">
                <div className="value">+10</div>
                <div className="label">07.13</div>
              </div>
              <div className="date-number">
                <div className="value">+10</div>
                <div className="label">今日</div>
              </div>
              <div className="date-number">
                <div className="value">待签</div>
                <div className="label">07.15</div>
              </div>
            </div> */}
            
          </Swiper>

          <div className="swiper-button-prev" style={{backgroundImage:"url(/resources/welfare/prev-button.png)",display:(routerIndex==1?'block':'none')}}></div>
          <div className="swiper-button-next" style={{backgroundImage:"url(/resources/welfare/next-button.png)",display:(routerIndex==0?'block':'none')}}></div>

        </div>

      </div>
      {/* <NavLink user={true} className={'btn ' + (data?.todaySigned ? 'disable' : '')} onClick={() => signOperate()}>
        {data?.todaySigned ? '今日已签' : '签到+' + (data.todaySignGetBalance ?? '?')}
        {data?.todaySigned ? 
        <div className="tips" style={{backgroundImage:"url(/resources/task/sign-already-tips.png)"}}>
          {data.nextReward}
        </div>
        :null}
      </NavLink> */}

      {data && init ? 
      <div className={"sign-btns" + (data?.isVip ? ' sign-one-btn' : '') }>
        {
          data?.isVip ? <>
          <NavLink user={true} style={{backgroundImage:"url(/resources/task/"+(data?.todaySigned ? 'critical-already.png' : 'critical.png')+")"}} className={'btn critical' + (data?.todaySigned ? 'disable' : '')} onClick={() => signOperate()}>
            {data?.isVip && data?.todaySigned ? '今日已签' : 
            <div className="btn-des"> <div className="text">暴击签到</div> <div className="balence-nums">+{(data.todaySignGetBalance ?? '?')}金币</div> </div>
            }
          </NavLink>
          </>
          : <>
          <NavLink user={true} style={{backgroundImage:"url(/resources/task/"+(data?.todaySigned ? 'normal-already.png' : 'normal.png')+")"}} className={'btn normal' + (data?.todaySigned ? 'disable' : '')} onClick={() => signOperate()}>
            {data?.todaySigned ? '今日已签' : 
            <div className="btn-des"> <div className="text">普通签到</div> <div className="balence-nums">+{(data.todaySignGetBalance ?? '?')}金币</div> </div>
            }
          </NavLink>
          <NavLink user={true} onClick={() => criticalHit()} style={{backgroundImage:"url(/resources/task/critical.png)"}} className={'btn critical'} >
            <div className="btn-des">
              <div className="text">{data?.todaySigned ? '去开卡' : '暴击签到'}</div> 
              <div className="balence-nums">{data?.todaySigned ? '明日暴击签' : '最高+6金币'}</div> 
            </div>
          </NavLink>
          
          <div className="sign-bg-bottom"><img src="/resources/task/sign-bg-bottom.png" /></div>
          </>
        }

      </div>
      : null}

    </div>
    <img src={'/resources/task/sign-segmentation.png'}/>
    <div className="sign-rules">
      <div className="title">签到规则</div>
      <div className="text">
        <div className="tags">
          <div className="tgimgs">
          <img src="/resources/task/rule_one.png" />
          </div>
          每天签到可获得1金币
          {/* <span className="seaiar">1</span><span><img src="/resources/task/rule_one.png" />第1天签到可获1金币，第2天签到可获2金币，依此类推，上限5金币</span> */}
        </div>
        <div className="tags">
          {/* <span className="seaiar">2</span><span>5天为一个签到周期，第6天从1金币开始重新计算</span> */}
          <div className="tgimgs">
          <img src="/resources/task/rule_two.png" />
          </div>
          开通会员可享受签到金币加成，具体见会员详情页介绍
        </div>
        {/* <div className="tags"> */}
          {/* <span className="seaiar">3</span><span>断签回到第一天重新签到</span> */}
          {/* <div className="tgimgs"> */}
          {/* <img src="/resources/task/rule_three.png" /> */}
          {/* </div> */}
          {/* 断签回到第一天重新签到 */}
        {/* </div> */}
      </div>
    </div>

    {/* <div className="sign-reward">
      <div className="title">
        <img src="/resources/task/sign-reward.png" />
      </div>
      <div className="progress">
        <div className="item">
          <img src="/resources/task/reward-1.png" />
        </div>
        <div className="item">
          <img src="/resources/task/reward-2.png" />
        </div>
        <div className="item">
          <img src="/resources/task/reward-3.png" />
        </div>
        <div className="item">
          <img src="/resources/task/reward-4.png" />
        </div>
        <div className="item">
          <img src="/resources/task/reward-5.png" />
        </div>
      </div>
    </div> */}

    {/** 新提醒 **/}
    {/* <div className="sign-reward">
      <div className="title">
        <img src="/resources/task/sup-title.png" />
      </div>
      <div className="progress">
        <div className="item">
          <img src="/resources/task/sup-month.png" />
        </div>
        <div className="item">
          <img src="/resources/task/sup-season.png" />
        </div>
        <div className="item">
          <img src="/resources/task/sup-year.png" />
        </div>
      </div>

      <NavLink user={false} to="/save/newvip" className="operate">
        立即开通{'>'}{'>'}
      </NavLink>
    </div> */}

    <div className="sign-woolgathering" style={{backgroundImage:"url(/resources/task/woolgathering-bg.png)"}}>
      <div className="title"><img src="/resources/task/sign-woolgathering.png" /></div>

      <div className="items">
        {data?.recommendItems?.map((item, i) => {
          return <div key={i} className="item">
            <div className="icon">
              <img src={item.icon} />
            </div>
            <div className="entry">
              <div className="info">
                <div className="name">{item.title}</div>
                <div className="desc">{item.desc}</div>
              </div>
              <NavLink className="operate" user={false} to={(() => {
                if(item.type == 'mcard') {
                  return '/save/newcard';
                } else if(item.type == 'vip') {
                  return '/save/newvip';
                } else if(item.type == 'tryPlay') {
                  return '/task/tryplay';
                }
              })()}>
                {item.button}
                <img src="/resources/task/woolgathering-left.png" />
              </NavLink>
            </div>
          </div>
        })}

      </div>

    </div>

    {/* <div to={"/save?type=vip"} className="sign-coin-award">
      <NavLink to={"/save?type=vip"}>
        <img src="/resources/task/fb.png" />
      </NavLink>
    </div> */}

    {/* <div className="sign-title">
      <img src="/resources/task/sign-title-milu.png" alt=""/>
      本月已经连续签到{data.month_keep_day}天
      本月最长连续签到{data.month_max_keep_day}天
    </div> */}
    {/* <div className="sign-calendar">
      <div className="title">{moment().format('YYYY年MM月')}</div>
      <div className="main clearfix">
        <div className="item disable">日</div>
        <div className="item disable">一</div>
        <div className="item disable">二</div>
        <div className="item disable">三</div>
        <div className="item disable">四</div>
        <div className="item disable">五</div>
        <div className="item disable">六</div>
        {calendar.map(item => <div className={'item'
        + (item.format('YYYY-MM') !== moment().format('YYYY-MM') ? ' disable' : ' sign')
        + (data.sign_date_list?.find(item2 => item2 === item.format('YYYY-MM-DD')) ? ' active' : '')
        } style={{
          backgroundImage: 'url(' + (
            data.sign_date_list?.find(item2 => item2 === item.format('YYYY-MM-DD')) ? '/resources/task/sign2.png' : (item.format('YYYY-MM') === moment().format('YYYY-MM') ? '/resources/task/sign1.png' : '')
          ) + ')'
        }}>
          {item.format('DD')}
        </div>)}
      </div>
    </div> */}
    {/* <div className="sign-operate">
      <NavLink to="/save?type=vip" className="vip">开通会员 享金币加成</NavLink>
      <div className="role" onClick={() => Dialog.alert('签到规则', <><div className="sign-record-desc">
        <p>1.普通用户每签到1次可获得1金币奖励，会员每签到一次可额外获得相应身份金币奖励（子爵额外得1金币，伯爵2金币，公爵3金币，国王5金币，皇帝20金币）；</p>
        <p>2.连续签到<span>3天／7天／15天／28天</span>，分别可领取 额外奖励<span>10金币／20金币／50金币／100金币</span>。 连续签到时间越长，金币越丰厚；</p>
        <p>3.普通用户连续签到满28天，可获得208个积 分；</p>
        <p>4.签到中断或每月第1天，连续签到天数将重新 进行计算；</p>
        <p>5.同一手机／设备／身份证视为同一用户；</p>
        <p>6.本活动最终解释权归咪噜游戏官方所属 </p>
        </div>
      </>)}>
        查看签到规则
      </div>
    </div> */}
    {/* {loaded && !signInfo.today_issign && sign ? <div className="sign-alert"> */}
    {sign ? <div className="sign-alert">
      <div className="mask" onClick={() => setSign(false)}/>
      <div className="entity" style={{backgroundImage: 'url(/resources/task/sign-alert.png)'}}>
        <div className="info">恭喜您本次签到<br/>获得{data.today_sign_get_balance}金币</div>
        <div className="desc">您已经连续签到{data.month_keep_day}天</div>
        <div className="btn" onClick={() => setSign(false)}>好的</div>
      </div>
    </div> : null}
  </div>;
};